<template lang="pug">
.border-custom.bg-white(v-if="prospectState")

  .container-pec-mobile-table.mx-auto.mx-lg-0.px-4.col-lg-12.pt-4(style="z-index: 10;")

    .col-8.offset-md-2.offset-xl-0.d-none.d-lg-inline-block.pb-4.d-xl-none
      .fluid-card

        .row.mx-1.mt-2
          ProgressCard(
            imageIcon="claims-1",
            :currentStep="3",
            stepTitle="Viewing Plans",
            @handleLeftClick="prevStep",
            @handleRightClick="nextStep")

        .d-inline-block
          span.font-weight-medium Here are plans based on your requirements
          br
          br

        .font-sm.d-inline-block
          | Find the right balance of coverage, savings,
          | and service - and select the plan that is best for your business.
          b-link(
            href="https://blog.getnovaapp.com/how-to-choose-the-right-group-health-policy-for-your-employees/",
            target="_blank")
            span.font-sm Need help?

    .col-12.col-lg-3.d-inline-block.px-2.pb-2.mx-auto
      .fluid-card

        .row.mx-1.mt-2.d-lg-none.d-xl-flex
          ProgressCard(
            imageIcon="claims-1",
            :currentStep="3",
            stepTitle="Viewing Plans",
            @handleLeftClick="prevStep",
            @handleRightClick="nextStep")

        .d-lg-none.d-xl-inline-block
          span.font-weight-medium Here are plans based on your requirements
          br
          br
        .row.w-100.pb-2
          .col-6.col-lg-12.col-xl-6(v-if="prospectState.employeeCount")
            .d-none.d-lg-block.d-xl-none
              br
              br
            .font-hc.text-gray-600 EMPLOYEES
            h2.pt-2 {{ prospectState.employeeCount }}

          .col-6.col-lg-12.col-xl-6.pr-xl-0(v-if="prospectState.meta.coverage_type")
            .pb-1.font-hc.text-gray-600 COVERAGE TYPE
            .row.pl-3
              img.coverage-type-image.mt-1.mr-2(
                :src="require(`@/assets/images/coverage${this.prospectState.meta.coverage_type}.svg`)")
              .font-sm.font-weight-medium.break-spaces(
                :class="[this.prospectState.meta.coverage_type==='E'?'mt-2':'']") {{ getCoverageType() }}

        .font-sm.d-lg-none.d-xl-inline-block
          | Find the right balance of coverage, savings,
          | and service - and select the plan that is best for your business.
          b-link(
            href="https://blog.getnovaapp.com/how-to-choose-the-right-group-health-policy-for-your-employees/ ",
            target="_blank")  Need help?

    .d-none.d-lg-inline-block.px-0.col-12(
      v-for="policy in prospectState.pricing_table_state.policies",
      :key="policy.id",
      :class="`col-lg-${12/prospectState.pricing_table_state.fields.length}`")
      n-card.mx-auto.custom-width(
        :cardTitle="policy.cardTitle",
        :variant="(selectedPolicyVariant \
          && selectedPolicyVariant===policy.termPrice?'selected-':'')+'solid-'+policy.variant",
        :imageIcon="policy.imageIcon",
        :buttonText="selectedPolicyVariant \
          && selectedPolicyVariant===policy.termPrice?'Continue with this':policy.buttonText",
        :buttonRightImageIcon="selectedPolicyVariant \
          && selectedPolicyVariant===policy.termPrice?'chevron-right':null",
        :termPrice="parseInt(prospectState.pricing_table_state.items\
          [prospectState.pricing_table_state.items.length-3]\
          [policy.termPrice]*1.18) / (prospectState.meta.employee_count || 1)",
        :cardText="policy.cardText", @buttonClick="pickPlan(policy.id, policy.termPrice)")

    .d-inline-block.d-lg-none.px-2.col-12(
      v-for="policy in prospectState.pricing_table_state.policies",
      :key="policy.id+10")
      .rounded.my-4(:class="'border-'+policy.variant")
        n-card.card-mobile.rounded-bottom-none(
          :cardTitle="policy.cardTitle",
          :variant="'solid-'+policy.variant",
          :imageIcon="policy.imageIcon",
          :termPrice="parseInt(prospectState.pricing_table_state.items\
            [prospectState.pricing_table_state.items.length-3]\
            [policy.termPrice]*1.18) / (prospectState.meta.employee_count || 1)",
          :cardText="policy.cardText",
          :disableButton="true")
        table.table-mobile
          tr.table-row-mobile(v-for="item in itemsMobile")
            td.text-gray-600 {{ item.feature }}
            td
              span.icon-check-circle(v-if="item[policy.termPrice].toString().trim()=='yes'")
              span.icon-cancel.text-gray-500(v-else-if="item[policy.termPrice].toString().trim()=='no'")
              span(
                v-else-if="item[policy.termPrice].toString().charAt(0) \
                ==='!'") {{ item[policy.termPrice].substring(1) | inr }}
              span(v-else) {{ item[policy.termPrice] | inr }}
        .row
          .col-10.offset-1
            n-button.mt-2.font-sm(
              variant="link",
              block,
              :disabled="false",
              :buttonText="'View Details ->'",
              @click="openFeatureListModal(policy)")
            n-button.mt-2.mb-4(
              :variant="policy.variant",
              block,
              :disabled="false",
              @click="pickPlan(policy.id, policy.termPrice)",
              :buttonText="selectedPolicyVariant && selectedPolicyVariant===policy.termPrice\
                ?'Continue with this plan':'Pick this plan'",
              :rightImageIcon="selectedPolicyVariant && \
                selectedPolicyVariant===policy.termPrice?'chevron-right':null")

  .row.pt-0.d-none.d-lg-flex
    .col-md-12.table-container
      b-table.table-quote-sheet(
        fixed,
        foot-clone,
        primary-key="id",
        :items="items",
        :tbody-transition-props="{name: 'row-list'}",
        :fields="prospectState.pricing_table_state.fields",
        :no-border-collapse="true",
        thead-class="d-none")

        template(#cell(feature)="scope")
          div
            i.col-md-1.pl-0.pr-2.d-inline-block.align-middle(
              :class="[\
                `icon-${scope.item.icon}`, \
                `text-${scope.item.iconColor}`, \
                scope.item.iconOpacity?`ic-${scope.item.iconOpacity}`:'']")
            span.hb5.pt-2.col-md-11.d-inline-block.align-middle(
              v-if="scope.item.feature",
              :id="`pricing-table-row-header-${scope.index}`",
              tabindex="-1",
              :class="[scope.item.icon?'regular-feature':'', scope.item.popoverText?'underlined':'']")
              | {{ scope.item.feature }}

            b-popover(
              v-if="scope.item.popoverText",
              :target="`pricing-table-row-header-${scope.index}`",
              triggers="focus hover",
              placement="bottom",
              :fallback-placement="['bottom','bottom','bottom','bottom']")
              b-card.overflow-hidden(
                :class="`popover-custom-card`",
                :ref="`pricing-table-row-header-${scope.index}`")
                span.popover-text.break-spaces {{ scope.item.popoverText }}
                u.d-block(v-if="scope.item.popoverLink")
                  b-link.text-success(:href="scope.item.popoverLink", target="_blank")
                    span Learn more
                    span.no-letter-spacing.text-decoration-none ->

        template(#cell()="scope")
          div
            span.icon-check-circle(v-if="scope.value.toString().trim()=='yes'")
            span.icon-cancel.text-gray-500(v-else-if="scope.value.toString().trim()=='no'")
            span(v-else-if="scope.value.toString().charAt(0)==='!'") {{ scope.value.substring(1) | inr }}
            span(v-else) {{ scope.value | inr }}

        template(#bottom-row="scope")
          b-td.align-middle.cursor-pointer(
            :colspan="prospectState.pricing_table_state.fields.length",
            @click="toggleExpansion()")
            .col-md-12.text-left.max-width-100
              span.hb5 View detailed policy parameters
              i.align-middle.float-right(:class="[isTableExpanded?'icon-chevron-up':'icon-chevron-down']")

        template(v-for="field in prospectState.pricing_table_state.fields", #foot()="scope")
          .footer.font-weight-medium.py-2
            | {{ getQuoteWithGst(prospectState, scope) }}

      .row
        .col-3.d-inline-block.font-sm
          span.mt-2 You can also customize these plans as per your needs
        .col-3.d-inline-block.px-0.px-xl-4
          n-button(
            :variant="'outline-primary'",
            :disabled="false",
            block,
            @click="showCustomizationModal(0)",
            :buttonText="'Customize Nova Basic'")
        .col-3.d-inline-block.px-0.px-xl-4
          n-button(
            :variant="'outline-dark'",
            :disabled="false",
            block,
            @click="showCustomizationModal(1)",
            :buttonText="'Customize Nova Standard'")
        .col-3.d-inline-block.px-0.px-xl-4
          n-button(
            :variant="'outline-success'",
            :disabled="false",
            block,
            @click="showCustomizationModal(2)",
            :buttonText="'Customize Nova Prime'")

      .row.pt-2
        .col-lg-3.d-inline-block
        .col-lg-3.d-inline-block.px-0.px-xl-4
          n-button(
            :variant="'primary'",
            :disabled="false",
            block,
            @click="pickPlan(1, 'basic')",
            :buttonText="selectedPolicyVariant && selectedPolicyVariant==='basic'\
              ?'Continue with this plan':'Pick this plan'",
            :rightImageIcon="selectedPolicyVariant && selectedPolicyVariant==='basic'\
              ?'chevron-right':null")
        .col-lg-3.d-inline-block.px-0.px-xl-4
          n-button(
            :variant="'dark'",
            :disabled="false",
            block,
            @click="pickPlan(2, 'standard')",
            :buttonText="selectedPolicyVariant && selectedPolicyVariant==='standard'\
              ?'Continue with this plan':'Pick this plan'",
            :rightImageIcon="selectedPolicyVariant && selectedPolicyVariant==='standard'\
              ?'chevron-right':null")
        .col-lg-3.d-inline-block.px-0.px-xl-4
          n-button(
            :variant="'success'",
            :disabled="false",
            block,
            @click="pickPlan(3, 'prime')",
            :buttonText="selectedPolicyVariant && selectedPolicyVariant==='prime'\
              ?'Continue with this plan':'Pick this plan'",
            :rightImageIcon="selectedPolicyVariant && selectedPolicyVariant==='prime'\
              ?'chevron-right':null")

    policy-customization-modal(
      :id="'customization-modal'",
      :policyData="prospectState",
      :planIndex="planIndex",
      :editableData="editableData",
      @saveChangesToTable="saveChangesToTable",
      @pickPlan="pickPlanFromCustomizationModal")

  .expanded-feature-list-modal-mobile(v-if="isFeatureListModal")
    div(:class="'bg-'+mobileModalPolicy.variant", style="height: 8rem")
      .text-white.font-lg.modal-close-button-mobile(
        :class="mobileModalPolicy.variant",
        @click="closeFeatureListModal(mobileModalPolicy.termPrice)")
        .icon-cross
      .modal-header-mobile
        i.h3.mr-2.align-middle(
          v-if="mobileModalPolicy.imageIcon",
          :class="[`icon-${mobileModalPolicy.imageIcon}`, `text-white`]")
        .d-inline-block.pb-2.h5 {{ mobileModalPolicy.cardTitle }}
        .font-lg.pl-2
          | {{ getMobileModalPolicy(prospectState, mobileModalPolicy) }} / Employee
        .font-sm.term-text.pt-1.pl-2
          span Annually
    .expanded-list-table-wrapper-mobile
      div(style="height: 100%; overflow-y: auto;")
        table.table-mobile
          tr.table-row-mobile(v-for="item in itemsMobile", :key="item.feature")
            td.px-0
              i.align-middle(
                :class="[\
                  `icon-${item.icon}`, \
                  `text-${item.iconColor}`, \
                  item.iconOpacity?`ic-${item.iconOpacity}`:'']")
            td.w-50.table-feature-mobile {{ item.feature }}
            td
              span.icon-check-circle(v-if="item[mobileModalPolicy.termPrice].toString().trim()=='yes'")
              span.icon-cancel.text-gray-500(v-else-if="item[mobileModalPolicy.termPrice].toString().trim()=='no'")
              span(
                v-else-if="item[mobileModalPolicy.termPrice].toString().charAt(0) \
                ==='!'") {{ item[mobileModalPolicy.termPrice].substring(1) | inr }}
              span(v-else) {{ item[mobileModalPolicy.termPrice] | inr }}

    .mx-0.bottom-section-modal-mobile
      .w-75.mx-auto.d-table.h-100
        .d-table-cell.align-middle.py-2
          n-button.my-1(
            :variant="mobileModalPolicy.variant",
            block,
            :disabled="false",
            @click="showCustomizationModal(0)",
            :buttonText="'Pick this plan'")

  n-confirm-modal(
    :id="'confirm-modal'",
    :planIndex="planIndex",
    :data="prospectState",
    :variant="variant",
    parent="policy-calculator",
    @showInfoModal="pickPlanAfterConfirmation")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import NCard from "./components/NovaCard.vue";
import PolicyCustomizationModal from "./components/PolicyCustomizationModal.vue";
import ProgressCard from "./components/ProgressCard.vue";
import resDefs from "./definitions";
import NConfirmModal from "./components/NovaConfirmModal.vue";
import utils from "@/utils";
export default {
  name: "PricingTable",
  components: {
    NCard,
    NButton,
    PolicyCustomizationModal,
    ProgressCard,
    NConfirmModal,
  },
  props: {
    prospectState: {
      type: Object,
      default: null,
    },
  },
  data() {
    const resName = "prospects";
    const resDef = resDefs[resName];
    return {
      resName,
      resDef,
      items: [],
      itemsMobile: [],
      isTableExpanded: false,
      planIndex: -1,
      editableData: [],
      isFeatureListModal: false,
      mobileModalPolicy: {},
      scrollTo: 0,
      selectedPolicyVariant: "",
      variant: "",
      confirmPlanIndex: -1,
    };
  },
  watch: {
    prospectState: function () {
      this.changeTableSize();
      this.editableData = this.prospectState.pricing_table_state.items.filter(
        (item) => {
          return [
            "sum insured",
            "normal room rent limit",
            "maternity benefits - limits and coverages",
            "wait period",
          ].includes(item.feature.trim().toLowerCase());
        }
      );
      this.selectedPolicyVariant = this.prospectState.meta.selectedPolicy;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.changeTableSize();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll(e) {
      this.windowTop = window.top.scrollY;
    },
    toggleExpansion() {
      this.isTableExpanded = !this.isTableExpanded;
      this.changeTableSize();
    },
    showCustomizationModal(planIndex) {
      this.planIndex = planIndex;
      this.$bvModal.show("customization-modal");
    },
    quoteWithGst(quote) {
      return isNaN(quote) ? quote + " (Including GST)" : utils.toINR(quote * 1.18);
    },
    changeTableSize() {
      if (this.prospectState) {
        if (this.isTableExpanded) {
          this.items = this.prospectState.pricing_table_state.items.slice(
            0,
            this.prospectState.pricing_table_state.items.length - 1
          );
          this.itemsMobile = this.prospectState.pricing_table_state.items.slice(
            0,
            this.prospectState.pricing_table_state.items.length
          );
        } else {
          this.items = this.prospectState.pricing_table_state.items.slice(0, 8);
          this.itemsMobile = JSON.parse(JSON.stringify(this.items));
        }
        this.items.unshift({});
      }
    },
    getCoverageType() {
      switch (this.prospectState.coverageType) {
        case "E":
          return "Employee Only";
        case "ESC":
          return "Employee, Spouse\n& Children";
        case "ESCP":
          return "Employee, Spouse,\nChildren & Parents";
        default:
          return "Data not found";
      }
    },
    nextStep() {
      if (this.prospectState.meta.selectedPolicy) {
        this.$router.push({
          name: "addons",
          params: { id: this.$route.params.id },
        });
      } else {
        this.$store.commit("addAlert", {
          variant: "danger",
          message: "Please pick a plan before continuing to the next step",
        });
      }
    },
    prevStep() {
      this.$router.push({
        name: "employees",
        params: { id: this.$route.params.id },
      });
    },
    saveChangesToTable(changes) {
      const items = this.prospectState.pricing_table_state.items;
      for (let i = 0; i < items.length; i++) {
        const changedItem =
          changes.editableDataChanges[
            items[i].feature.replace(/\s/g, "").toLowerCase()
          ];
        if (changedItem) {
          items[i][changes.policy.termPrice] = changedItem;
        }
      }
      this.$emit("saveChanges", true);
    },
    openFeatureListModal(policy) {
      this.scrollTo = window.top.scrollY;
      this.mobileModalPolicy = policy;
      const wrapper = document.getElementsByClassName("wrapper")[0];
      wrapper.style.overflow = "hidden";
      this.toggleExpansion();
      this.isFeatureListModal = true;
    },
    closeFeatureListModal() {
      const wrapper = document.getElementsByClassName("wrapper")[0];
      wrapper.style.overflow = "visible";
      this.toggleExpansion();
      this.isFeatureListModal = false;
      window.scrollTo({
        top: this.scrollTo,
        behavior: "smooth",
      });
    },
    pickPlan(selectedPolicyId, selectedPolicy) {
      this.policyAwaitingConfirmation = selectedPolicy;
      this.planIndex = selectedPolicyId - 1;
      this.variant = this.prospectState.pricing_table_state.policies[
        this.planIndex
      ].variant;
      this.$bvModal.show("confirm-modal");
    },
    pickPlanFromCustomizationModal(selectedPolicy) {
      this.policyAwaitingConfirmation = selectedPolicy.name;
      this.planIndex = selectedPolicy.id - 1;
      this.variant = this.prospectState.pricing_table_state.policies[
        this.planIndex
      ].variant;
      this.$bvModal.show("confirm-modal");
    },
    pickPlanAfterConfirmation() {
      const fields = this.prospectState.pricing_table_state.fields;
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].key === this.policyAwaitingConfirmation) {
          fields[i].variant = fields[i].key + "-selected";
        } else {
          fields[i].variant = fields[i].key;
        }
      }
      this.$set(
        this.prospectState.meta,
        "selectedPolicy",
        this.policyAwaitingConfirmation
      );
      this.$emit("saveChanges");
      this.nextStep();
    },
    getQuoteWithGst(prospectState, scope) {
      return this.quoteWithGst(
        prospectState.pricing_table_state.items[
          prospectState.pricing_table_state.items.length - 3
        ][scope.field.key]
      );
    },
    getMobileModalPolicy(prospectState, mobileModalPolicy) {
      return utils.toINR(
        (parseInt(
          prospectState.pricing_table_state.items[
            prospectState.pricing_table_state.items.length - 1
          ][mobileModalPolicy.termPrice]
        ) /
          (prospectState.meta.employee_count || 1))
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.table-container {
  width: 100%;
  padding: 0 2.25rem;
  top: -2rem;
  z-index: 0;
}
.explore-data {
  background-color: #0b3948;
  color: white;
  padding: 1.25rem;
}
.border-custom {
  border-radius: 0;
}
@media (min-width: 1200px) {
  .border-custom {
    border-radius: 0.25rem;
  }
}
.fluid-card {
  display: flex;
  flex-direction: column;
}
hr {
  border-color: white;
}
.coverage-type-image {
  height: 24px;
  width: 24px;
}
tr {
  height: 68px;
}
td {
  text-align: center;
  vertical-align: middle !important;
}
th {
  vertical-align: middle !important;
  font-size: 1rem;
  line-height: 19px;
}
tr:first-child > th,
tr:first-child > td {
  border-top: 0;
}
.custom-width {
  width: calc(100% - 3rem);
}
@media (min-width: 992px) {
  .custom-width {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .custom-width {
    width: 16rem;
  }
}
.border-primary {
  border: 2px solid;
  border-color: $blue-500 !important;
}
.border-dark {
  border: 2px solid;
  border-color: $gray-900 !important;
}
.border-success {
  border: 2px solid;
  border-color: $teal-800 !important;
}
.card-mobile {
  position: relative;
  left: -2px;
  top: -2px;
  width: calc(100% + 4px);
}
.table-mobile {
  margin: 0 auto;
  width: 90%;
}
.table-row-mobile {
  height: 3.5rem;

  border-bottom: 1px solid $gray-300;
  > td {
    text-align: left;
    font-size: 12px;
    padding-right: 1rem !important;
  }
}
.expanded-feature-list-modal-mobile {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 10000;
  & > .bg-success {
    background-color: $teal-800 !important;
  }
}
.modal-close-button-mobile {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 5rem;
  text-align: center;
  padding-top: 0.55rem;
  position: absolute;
  right: 2rem;
  top: 1.5rem;
  cursor: pointer;
  &.primary {
    background-color: $blue-400;
  }
  &.dark {
    background-color: $gray-800;
  }
  &.success {
    background-color: $teal-700;
  }
  & > .icon-cross {
    font-size: 1.5rem;
  }
}
.modal-header-mobile {
  color: white;
  padding: 1.5rem 0 0 2rem;
}
.bottom-section-modal-mobile {
  // height: 4rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $gray-100;
  box-shadow: 0px -15px 20px rgba(0, 0, 0, 0.04),
    0px -2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem 0.25rem 0 0;
}
.expanded-list-table-wrapper-mobile {
  height: calc(100vh - 12rem);
}
.max-width-100 {
  max-width: 100%;
}
.font-sm > a {
  font-size: 0.75rem;
}
</style>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";

//Column highlights
@mixin accent-background(
  $table-column-accent-left,
  $table-column-accent-right,
  $table-column-accent-opacity: 0.33
) {
  .table-basic,
  .table-basic-selected {
    background: linear-gradient(
      to right,
      white $table-column-accent-left,
      rgba(233, 242, 255, $table-column-accent-opacity)
        $table-column-accent-left,
      rgba(233, 242, 255, $table-column-accent-opacity)
        $table-column-accent-right + 0.3%,
      white $table-column-accent-right + 0.3%
    );
    vertical-align: middle !important;
    & > div {
      &.footer {
        font-weight: 500;
        text-align: center;
        color: $blue-900;
        top: 35%;
      }
    }
  }
  .table-basic-selected {
    background: linear-gradient(
      to right,
      white $table-column-accent-left,
      rgba(233, 242, 255, 1) $table-column-accent-left,
      rgba(233, 242, 255, 1) $table-column-accent-right + 0.3%,
      white $table-column-accent-right + 0.3%
    );
    vertical-align: middle !important;
  }
  .table-prime,
  .table-prime-selected {
    padding: 1rem 0 !important;
    background: linear-gradient(
      to right,
      white $table-column-accent-left - 1%,
      rgba(240, 255, 254, $table-column-accent-opacity)
        $table-column-accent-left - 1%,
      rgba(240, 255, 254, $table-column-accent-opacity)
        $table-column-accent-right - 0.5%,
      white $table-column-accent-right - 0.5%
    );
    vertical-align: middle !important;
    & > div {
      &.footer {
        font-weight: 500;
        text-align: center;
        color: $teal-900;
        top: 35%;
      }
    }
  }
  .table-prime-selected {
    background: linear-gradient(
      to right,
      white $table-column-accent-left - 1%,
      rgba(240, 255, 254, 1) $table-column-accent-left - 1%,
      rgba(240, 255, 254, 1) $table-column-accent-right - 0.5%,
      white $table-column-accent-right - 0.5%
    );
    vertical-align: middle !important;
  }
  .table-standard,
  .table-standard-selected {
    padding: 1rem 0 !important;
    background: linear-gradient(
      to right,
      white $table-column-accent-left - 0.5%,
      rgba(242, 246, 255, $table-column-accent-opacity)
        $table-column-accent-left - 0.5%,
      rgba(242, 246, 255, $table-column-accent-opacity)
        $table-column-accent-right - 0.5%,
      white $table-column-accent-right - 0.5%
    );
    vertical-align: middle !important;
    & > div {
      &.footer {
        font-weight: 500;
        text-align: center;
        color: $gray-900;
        top: 35%;
      }
    }
  }
  .table-standard-selected {
    padding: 1rem 0 !important;
    background: linear-gradient(
      to right,
      white $table-column-accent-left - 0.5%,
      rgba(242, 246, 255, 1) $table-column-accent-left - 0.5%,
      rgba(242, 246, 255, 1) $table-column-accent-right - 0.5%,
      white $table-column-accent-right - 0.5%
    );
    vertical-align: middle !important;
  }
}
@include accent-background(0%, 100%);
@media (min-width: 1200px) {
  @include accent-background(4%, 97%);
}
@media (min-width: 1440px) {
  @include accent-background(8%, 92.5%);
}
</style>
